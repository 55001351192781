import React, { useRef, useState } from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SecondaryHeader from "../components/SecondaryHeader"
import Helmet from "react-helmet"
import { API_URL, RECAPTCHA_SITE_KEY } from "../utils/Constants"
import { useForm } from "react-hook-form"
import ReCAPTCHA from "react-google-recaptcha"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const ContactPage = () => {
  const [loading, setLoading] = useState(false)
  const recaptchaRef = useRef(null)

  const [alert, setAlert] = useState({
    show: false,
    success: true,
    message: "",
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  })

  const onSubmit = data => {
    const recaptchaValue = recaptchaRef.current.getValue()

    if (!recaptchaValue) {
      setAlert({
        show: true,
        success: false,
        message: "Before you proceed, please complete the captcha.",
      })

      setTimeout(() => {
        setAlert({
          show: false,
          success: false,
          message: "",
        })
      }, 2000)
      return
    }

    setLoading(true)
    fetch(`${API_URL}contact-us`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
      }),
    })
      .then(res => res.json())
      .then(resData => {
        if (resData?.errors) {
          setAlert({
            show: true,
            success: false,
            message: "Something went wrong while sending resume.",
          })
          return
        }

        reset()
        setAlert({
          show: true,
          success: true,
          message: "Your request has been submitted.",
        })
      })
      .catch(e => {
        setAlert({
          show: true,
          success: false,
          message: "Something went wrong while sending email.",
        })
      })
      .finally(() => {
        recaptchaRef.current.reset()
        setLoading(false)
        setTimeout(() => {
          setAlert({
            show: false,
            success: true,
            message: "",
          })
        }, 3500)
      })
  }

  const commonStyleForAlert = () => {
    return { padding: "2px 4px", width: "100%", color: "#fff" }
  }

  return (
    <Layout>
      <GatsbySeo
        title="description"
        description="Contact AlignerBase: Drop us a note or book a zoom call with us by selecting a convenient time from our online calendar!"
      />
      <Helmet>
        <meta charset="utf-8" />
        <script
          type="text/javascript"
          src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        ></script>
      </Helmet>
      <Seo title={`Contact us`} />
      <SecondaryHeader title={`Contact us`} />

      <div id="contact-page">
        <div className="contact">
          <div className="contact__section form-section">
            <h3>Write to Us</h3>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="contact__section__form"
            >
              <div className="row">
                <input
                  className="input first_name"
                  {...register("first_name")}
                  type={"text"}
                  placeholder="First Name"
                  required
                />
                <input
                  className="input last_name"
                  type={"text"}
                  {...register("last_name")}
                  placeholder="Last Name"
                  required
                />
              </div>
              <div className="row">
                <input
                  className="input phone"
                  {...register("contact")}
                  type={"phone"}
                  placeholder="Phone"
                  required
                />
              </div>

              <div className="row">
                <input
                  className="input email"
                  type={"email"}
                  {...register("email")}
                  placeholder="Email"
                  required
                />
              </div>
              <div className="row">
                <textarea
                  className="input message"
                  placeholder="Message"
                  {...register("message")}
                  required
                ></textarea>
              </div>

              {alert.show && (
                <div className="row">
                  <div
                    style={
                      alert.success
                        ? {
                            ...commonStyleForAlert(),
                            background: "#198754",
                          }
                        : {
                            ...commonStyleForAlert(),
                            background: "#dc3545",
                          }
                    }
                  >
                    {alert.message}
                  </div>
                </div>
              )}

              <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY} />

              <div className="pb-4" />

              <button type="submit" className="contact__section__form__btn">
                {loading ? <>Loading...</> : <>Submit</>}
              </button>
            </form>
          </div>
          {/* <div className="contact__section">
            <h3>..Or Book a call</h3>

            <iframe
              title={"hubspot"}
              style={{
                width: "100%",
                height: "700px",
              }}
              src={
                "https://meetings.hubspot.com/waqar-mian/onboarding-call?embed=true"
              }
            />
          </div> */}
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
